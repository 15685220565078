import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
} from '@headlessui/react'
import clsx from 'clsx'
import { Button, ThemeButton } from '../buttons'
import { CaretUp, CloseIcon, MenuIcon } from '../icons'
import { BlackHorizontalGlyph, WhiteHorizontalGlyph } from '../logos'
import { TypographyLabel } from '../typography'
import Popover from './popover'
import { Link, Navigate, useNavigate } from '@remix-run/react'
import AvatarPlaceholder from '#images/avatar-placeholder.png'
import AvatarPlaceholderDark from '#images/avatar-placeholder-dark.png'
import Avatar from '../avatars/avatar'
import { ContentTile } from '../content'
import fallbackAvatarImage from '#/images/fallbackAvatar.webp'
import { useRequestInfo } from '#app/utils/requestInfo.ts'

const AppNavigation = ({
	user = undefined,
	menuOptions = undefined,
	onLogin = undefined,
	onLogout = undefined,
	onUserProfile = undefined,
	updateTheme = undefined,
	theme = 'light',
	logoLink = undefined,
}: {
	user?:
		| {
				name: string
				id: string
				wallet?: string
				image: string
				accessLevel: { [key: string]: boolean }
		  }
		| 'loading'
	menuOptions?: {
		title: string
		description?: string
		action: () => void
		leftIcon?: React.ReactNode
		rightIcon?: React.ReactNode
		isVerified?: boolean
		image?: string
	}[]
	onLogin?: () => void
	onLogout?: () => void
	onUserProfile?: () => void
	updateTheme?: () => void
	theme: 'light' | 'dark'
	logoLink?: string
}) => {
	const requestInfo = useRequestInfo()
	const offlineMode = requestInfo.userPrefs.offlineMode
	return (
		<Disclosure as="header">
			{({ open, close }) => (
				<>
					<div
						className={clsx(
							'bg-white dark:bg-dark',
							'flex h-18 items-center justify-between p-4 sm:px-6 md:px-12',
							'border-b border-rulePrimaryLight dark:border-rulePrimaryDark',
						)}
					>
						<div className="flex dark:hidden">
							{logoLink ? (
								<Link to={logoLink}>
									<BlackHorizontalGlyph />
								</Link>
							) : (
								<BlackHorizontalGlyph />
							)}
						</div>
						<div className="hidden dark:flex">
							{logoLink ? (
								<Link to={logoLink}>
									<WhiteHorizontalGlyph />
								</Link>
							) : (
								<WhiteHorizontalGlyph />
							)}
						</div>
						<div className="hidden items-center gap-x-2 sm:flex sm:flex-1 sm:justify-end">
							{offlineMode === 'true' && (
								<TypographyLabel
									size="medium"
									isBold={true}
									additionalClasses="text-textPrimaryLight bg-yellow p-2"
								>
									DSN Mode: IPFS and Arweave OFFLINE
								</TypographyLabel>
							)}
							<ThemeButton
								theme={theme}
								updateTheme={updateTheme ? updateTheme : () => {}}
							/>

							{user && user !== 'loading' ? (
								<Popover
									popoverType="profile"
									user={user}
									options={menuOptions}
									userProfile={onUserProfile}
									logOut={onLogout}
									PopoverButton={
										<Avatar
											additionalClasses="ml-6"
											avatar={{
												src: user.image,
												name: user.name,
											}}
											size="small"
											isVerified={false}
										/>
									}
								/>
							) : user === 'loading' ? (
								<img
									className="ml-6 h-10 w-10"
									alt="loading avatar"
									src={
										theme === 'dark' ? AvatarPlaceholderDark : AvatarPlaceholder
									}
								/>
							) : (
								<Button size="medium" onClick={onLogin}>
									Sign In
								</Button>
							)}
						</div>
						<DisclosureButton className="flex h-10 w-10 items-center justify-center rounded-md border border-textPrimaryLight text-textPrimaryLight dark:border-white dark:text-white sm:hidden">
							{open ? <CloseIcon /> : <MenuIcon />}
						</DisclosureButton>
					</div>
					<AppNavMobile
						closeDisclosure={close}
						user={user}
						menuOptions={menuOptions}
						onUserProfile={onUserProfile}
						theme={theme}
						updateTheme={updateTheme ? updateTheme : () => {}}
						onLogin={onLogin ? onLogin : () => {}}
						onLogout={onLogout ? onLogout : () => {}}
					/>
				</>
			)}
		</Disclosure>
	)
}

export default AppNavigation

const navigationOptions = [
	{
		name: 'Platform',
		options: [{ name: 'Atomic Lore', href: 'https://www.atomicform.com/lore' }],
	},
	{
		name: 'Resources',
		options: [
			{ name: 'Documentation', href: 'https://www.atomicform.com/support' },
			{ name: 'Newsletter', href: 'https://www.atomicform.com/newsletter' },
		],
	},
	{
		name: 'Company',
		options: [
			{ name: 'FAQs', href: 'https://www.atomicform.com/support' },
			{ name: 'About', href: 'https://www.atomicform.com/about' },
			{ name: 'Careers', href: 'https://www.atomicform.com/careers' },
		],
	},
]

// enter options
// create disclosure prop
// https://headlessui.com/react/disclosure
const AppNavMobile = ({
	closeDisclosure,
	user = undefined,
	menuOptions = undefined,
	onLogin = undefined,
	onLogout = undefined,
	onUserProfile = undefined,
	updateTheme = undefined,
	theme = 'light',
	logoLink = undefined,
}: {
	closeDisclosure: () => void
	user?:
		| {
				name: string
				id: string
				wallet?: string
				image: string
				accessLevel: { [key: string]: boolean }
		  }
		| 'loading'
	menuOptions?: {
		title: string
		description?: string
		action: () => void
		leftIcon?: React.ReactNode
		rightIcon?: React.ReactNode
		isVerified?: boolean
		image?: string
	}[]
	onLogin?: () => void
	onLogout?: () => void
	onUserProfile?: () => void
	updateTheme?: () => void
	theme: 'light' | 'dark'
	logoLink?: string
}) => {
	const navigate = useNavigate()
	return (
		<div className="rounded-b-2xl bg-white drop-shadow-menu dark:bg-dark dark:drop-shadow-none sm:hidden">
			<DisclosurePanel>
				<div className="space-y-4 px-4 py-6">
					<div className="flex flex-col gap-y-2">
						<div className="flex items-center justify-between pb-4">
							{user && user !== 'loading' ? (
								<ContentTile
									title={user.name || ''}
									description=""
									image={user.image || fallbackAvatarImage}
									variant="user"
									isVerified={true}
									showBorder={false}
									showCaret={false}
								/>
							) : user === 'loading' ? (
								<img
									className="ml-6 h-10 w-10"
									alt="loading avatar"
									src={
										theme === 'dark' ? AvatarPlaceholderDark : AvatarPlaceholder
									}
								/>
							) : (
								<Button size="medium" onClick={onLogin}>
									Sign In
								</Button>
							)}
							<ThemeButton
								theme={theme}
								updateTheme={updateTheme ? updateTheme : () => {}}
							/>
						</div>
						{user && user !== 'loading' ? (
							<div className="flex h-10 items-center" onClick={onLogout}>
								<TypographyLabel
									size="medium"
									isBold={true}
									additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
								>
									Log out
								</TypographyLabel>
							</div>
						) : null}

						{menuOptions &&
							menuOptions.map(option => (
								<div
									key={option.title}
									className="flex h-10 items-center"
									onClick={e => {
										option.action()
										closeDisclosure()
									}}
								>
									<TypographyLabel
										key={option.title}
										size="medium"
										isBold={true}
										additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
									>
										{option.title}
									</TypographyLabel>
								</div>
							))}
						{user &&
						user !== 'loading' &&
						user.accessLevel &&
						user.accessLevel.createCollection ? (
							<div
								className="flex h-10 items-center"
								onClick={() => {
									navigate('/collectionAdd')
									closeDisclosure()
								}}
							>
								<TypographyLabel
									size="medium"
									isBold={true}
									additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
								>
									Create Collection
								</TypographyLabel>
							</div>
						) : null}
					</div>
					{navigationOptions.map(section => {
						return (
							<Disclosure
								key={section.name}
								defaultOpen={true}
								as="div"
								className="border-t border-dotted border-uiPrimaryLight py-4 dark:border-uiPrimaryDark"
							>
								<DisclosureButton className="group inline-flex items-center gap-x-2 text-textPrimaryLight dark:text-textPrimaryDark">
									<TypographyLabel
										key={section.name}
										size="medium"
										isBold={true}
									>
										{section.name}
									</TypographyLabel>
									<div className="group-data-[open]:rotate-180">
										<CaretUp />
									</div>
								</DisclosureButton>
								<DisclosurePanel
									as="div"
									className="flex flex-col pt-4 text-textSecondaryLight dark:text-textSecondaryDark"
								>
									{section.options.map(option => (
										<a key={option.name} href={option.href} target="_blank">
											<div className="flex h-10 items-center">
												<TypographyLabel
													key={option.name}
													size="medium"
													isBold={true}
												>
													{option.name}
												</TypographyLabel>
											</div>
										</a>
									))}
								</DisclosurePanel>
							</Disclosure>
						)
					})}
				</div>
			</DisclosurePanel>
		</div>
	)
}
